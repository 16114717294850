.galerie-container {
    padding: 20px;
    max-width: 1000px;
    margin: auto;
    font-family: Arial, sans-serif;
}

h1 {
    text-align: center;
    margin-bottom: 20px;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.image-item img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    cursor: pointer; 
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    padding: 10px;
    margin: 0 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    font-size: 0.9rem;
}

.pagination button.active {
    background-color: #660000;
}

@media (max-width: 768px) {
    .image-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    h1 {
        font-size: 1.5rem;
    }

    .pagination {
        justify-content: center;
    }

    .pagination button {
        width: auto;
        margin: 0 5px;
    }
}

@media (max-width: 480px) {
    .image-grid {
        grid-template-columns: 1fr;
    }

    h1 {
        font-size: 1.25rem;
    }

    .pagination button {
        padding: 8px;
        font-size: 0.9rem;
        margin: 0 5px;
    }
}
