@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&display=swap");

body,
html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
  background-color: #f4f4f4;
  overflow-x: hidden;
}

.slider-container {
  position: relative;
  width: 100%;
  height: 90vh;
  overflow: hidden;

}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 100%;
  transition: left 1.5s ease-in-out, opacity 2s ease-in-out;
  opacity: 0.5;
}

.slide.active {
  left: 0;
  opacity: 1;
}

.slide.outgoing {
  left: -100%;
  opacity: 0;
}

.slide-content {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
  padding: 10px;
  width: 80%;
}

.slide-content-h2 {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
  padding: 10px;
  width: 80%;
}

h2.top-text {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.bottom-text {
  background-color: #ff6600;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin-top: 20px;
}

.bottom-text:hover {
  background-color: #b30000;
  transform: scale(1.05);
}

.bottom-text:active {
  transform: scale(0.95);
}

.bottom-text:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
}

.slider-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: white;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.3s, transform 0.3s;
}

.dot.active {
  opacity: 1;
  transform: scale(1.2);
}

.section {
  padding: 50px 20px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.6s ease, transform 0.6s ease;
  transform: translateY(50px);
}

.section:nth-child(even) {
  background-color: #ffffff;
  color: rgb(77, 77, 77);
}

.section:nth-child(odd) {
  background-color: #f4f4f4;
  color: black;
}

.cursor {
  display: inline-block;
  width: 2px;
  height: 1.2em;
  background-color: white;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.about-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  background-color: white;
  gap: 40px;
}

.about-left {
  flex: 1;
}

.about-left h5 {
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 10px;
  color: #666;
}

.about-left h1 {
  font-size: 56px;
  font-weight: 1200;
  line-height: 1.2;
  margin: 0;
}

@keyframes slideIn {
  from {
    width: 0;
  }
  to {
    width: 100px;
  }
}

.underline {
  width: 100px;
  height: 4px;
  background-color: orange;
  margin-top: 15px;
  animation: slideIn 0.5s ease-in-out;
}

.about-right {
  flex: 2;
}

.about-right p {
  font-size: 14px;
  line-height: 1.8;
  color: #333;
  font-family: "Arial", "Dosis";
  font-weight: 400;
  margin-bottom: 10px;
}

.about-right h1 {
  font-size: 20px;
  line-height: 1.8;
  margin-bottom: 15px;
  color: #333;
  font-family: "Arial", sans-serif;
}

.carousel {
  background-color: #87848422;
  height: 400px;
  padding: 3rem;
}

.carousel h1 {
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 10px;
  font-size: 14px;
  color: #666;
}

.carousel-container {
  overflow: hidden;
  height: 370px;
  position: relative;
  background-color: #ffffff;
  border-radius: 12px;
  margin: 0 auto;
}

.carousel-track {
  display: flex;
  transition: transform 1s ease-in-out;
}

.carousel-item {
  min-width: 33.33%; 
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-item img {
  padding: 20px;
  width: 100%;
  max-width: 200px;
  max-height: 120px;
  object-fit: contain;
  border-radius: 8px;
}

.button {
  background-color: #ff6600;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.button:hover {
  background-color: #b30000;
  transform: scale(1.05);
}

.button:active {
  transform: scale(0.95);
}

.button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
}

.services-container {
  padding: 2rem;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin-top: 2rem;
}

.services-container h2 {
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 10px;
  font-size: 14px;
  color: #666;
}

.services-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.services-table th,
.services-table td {
  border: 1px solid #ccc;
  padding: 7px;
  text-align: left;
}

.services-table th {
  background-color: #ff6600;
  color: #fff;
  font-size: 24px;
}

.service-button:hover {
  background-color: #b30000;
}
.ceo-container {
  width: 90%;
  padding: 1rem;
  margin: 2rem auto;
  background-color: #f8f9fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  border-radius: 25px;
}

.header h1 {
  font-size: 2rem;
  color: #333;
  text-align: center;
}

.header p {
  text-align: center;
  color: #777;
  margin-top: 0.5rem;
}

.ceo-info-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ceo-info-box {
  flex: 1;
  margin-right: 1rem;
}

.blue-shape {
  background-color: #255885;
  color: white;
  padding: 1.5rem;
  border-radius: 50px;
  line-height: 1.6;
  height: 190px;
}
.ceo-photo {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 80px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.message-fl{
  background-color: #ff6600;
  border-radius: 70px;
  font-style: italic;
  padding: 10px;
  margin-left: 10px;
  }

.message h2 {
  background-color: #ff6600;
  padding: 1rem;
  color: white;
  border-radius: 19px;
  margin-left: 20px;
  display: inline-block;
}

.message-pp {
  margin-top: 0px;
  color: white;
  font-size: 14px;
  margin-left: 10px;
  text-decoration: underline;
  

}

.message blockquote {
  font-style: italic;
  color: white;
  font-size: 1.2rem;
}

.message-p {
  list-style-type: disc;
  margin-left: 2rem;
  list-style-type: circle;
  font-size: 14px;
  margin-top: 20px;
  line-height: 1.5;
  color: white;
  font-family: "Arial", "Dosis";
}

.signature {
  text-align: right;
  margin-top: 2rem;
}

.signature p {
  margin: 0;
}

.ceo-title {
  color: #f39c12;
  font-weight: bold;
  margin-top: 0.5rem;
  font-family: Arial, Helvetica, sans-serif;
}


@media (max-width: 1024px) {
  .about-container {
    flex-direction: column;
    padding: 20px;
    text-align: center;
  }

  .about-left,
  .about-right {
    flex: none;
    width: 100%;
    margin-bottom: 20px;
  }

  .about-left h1 {
    font-size: 36px;
  }

  .about-right h1 {
    font-size: 18px;
  }

  .about-right p {
    font-size: 12px;
  }

  .carousel h1 {
    font-size: 12px;
  }

  .carousel-item {
    min-width: 50%;
  }

  .carousel-item img {
    max-width: 150px;
  }
}

@media (max-width: 768px) {
  .about-container {
    padding: 10px;
  }

  .about-left h1 {
    font-size: 28px;
  }

  .about-right h1 {
    font-size: 16px;
  }

  .about-right p {
    font-size: 10px;
  }

  .carousel-item {
    min-width: 100%;
  }

  .carousel-item img {
    max-width: 100px;
  }

  .section {
    padding: 20px 10px;
  }
}

@media (max-width: 480px) {
  .slide-content {
    font-size: 1rem;
  }
  
  h2.top-text {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .bottom-text {
    font-size: 0.6rem;
  }

  .section {
    padding: 10px;
  }
  
  .carousel-item img {
    width: 30%;
    padding: 1px;
  }
  
  .carousel-container {
    height: 250px;
  }

  .services-table th,
  .services-table td {
    border: 1px solid #ccc;
    padding: 7px;
    text-align: left;
    font-size: 12px;
  }

  .carousel {
    background-color: #87848422;
    height: 270px;
    padding: 3rem;
  }
}
@media (max-width: 768px) {
  .ceo-info-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .ceo-info-box {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .ceo-photo {
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }

  .blue-shape {
    height: auto;
    padding: 1rem;
  }

  .message h2 {
    font-size: 1rem;
    height: auto;
    margin-left: 0px;

  }

  .message-p {
    font-size: 12px;
    margin-left: 1rem;
  }
  .message-fl{
    background-color: #ff6600;
    border-radius: 70px;
    font-style: italic;
    padding: 10px;
    margin-left: 0px;
    }
  .signature {
    text-align: right;
    margin-top: 2rem;
    font-size: 12px;
  }
  
  .signature p {
    margin: 0;
  }
}

@media (max-width: 480px) {
  .message h2 {
    font-size: 0.9rem;
  }

  .message-pp {
    font-size: 12px;
  }

  .ceo-photo {
    width: 150px;
    height: 150px;
  }
}