.footer {
  background-color: #111;
  color: #fff;
  padding: 20px 0;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-section {
  width: 30%;
  padding: 0 10px;
}

.footer-title {
  color: #ff6600;
  margin-bottom: 20px;
  font-size: 18px;
}

.footer-title-p {
  color: #ffffff;
  font-size: 15px;
}

.footer-title-h1 {
  padding: 1.2rem;
  font-size: 34px;
  font-weight: bold;
  color: white;
}

.logo-accent {
  color: #ff6600;
}

.footer-links {
  list-style: none;
  margin: 15px 0;
  padding: 0;
}

.footer-links li {
  margin-bottom: 8px;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
}

.footer-links a:hover {
  text-decoration: underline;
}

.contact-info {
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact-info i {
  color: #ff6600;
  font-size: 20px;
}

.contact-info a {
  color: #ff6600;
  font-size: 14px;
}

.contact-info a:hover {
  color: #b30000;
  font-size: 15px;
}

.subscribe-form {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.subscribe-form input {
  width: 70%;
  padding: 10px;
  margin-right: 5px;
  border: none;
  border-radius: 4px;
}

.subscribe-form button {
  background-color: #ff6600;
  border: none;
  color: white;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 4px;
}

.subscribe-form button:hover {
  background-color: #b30000;
}

.social-icons {
  margin-top: 15px;
}

.social-icons a {
  margin-right: 10px;
  color: #fff;
  font-size: 20px;
}

.social-icons a:hover {
  color: #ff6600;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #444;
}

.footer-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.footer-tag {
  background-color: #ff6600;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
}

.footer-tag:hover {
  background-color: #b30000;
}
.footer-title-h1 .underline {
  width: 100px;
  height: 2px;
  margin-left: 50px;
  background-color: #ff6600;
  animation: slideIn 0.5s ease-in-out;
}

@media (max-width: 768px) {
  .footer-section {
      width: 100%;
      font-size: 10px;
  }

  .footer-title {
      font-size: 16px;
  }

  .footer-title-h1 {
      font-size: 24px;
  }

  .footer-links a {
      font-size: 14px;
  }

  .subscribe-form input {
      width: 60%;
      height: 15px;
      margin-right: 0;
    }

  .subscribe-form button {
      width: 30%;
      margin-left: 10px;
  }
  .footer-title-p {
    color: #ffffff;
    font-size: 12px;
    width: 150px;
  }
  .footer-title-h1 .underline {
    width: 100px;
    height: 2px;
    margin-left: 30px;
    background-color: #ff6600;
    animation: slideIn 0.5s ease-in-out;
  }
  .contact-info a {
    color: #ff6600;
    font-size: 12px;
  }
  .contact-info i {
    color: #ff6600;
    font-size: 16px;
  }
  .footer-bottom {
    font-size: 10px;
  }
}
