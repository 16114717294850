.realisation-container {
    padding: 20px;
}

.title {
    text-align: center;
    margin-bottom: 20px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.realisation-group {
    margin: 20px 0;
    width: 100%;
}

.image-gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.image-item {
    cursor: pointer;
}

.gallery-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s;
}

.gallery-image:hover {
    transform: scale(1.05);
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-image {
    max-width: 90%;
    max-height: 90%;
}

.pagination {
    margin-top: 20px;
}

.page-button {
    margin: 0 5px;
    padding: 5px 10px;
}

.page-button.active {
    background-color: #ff6600;
    color: white;
}

@media (max-width: 768px) {
    .image-gallery {
        grid-template-columns: repeat(2, 1fr);
    }

    .title {
        font-size: 1.5rem;
    }

    .realisation-group {
        margin: 10px 0;
    }

    .page-button {
        padding: 5px 8px;
    }
}

@media (max-width: 480px) {
    .image-gallery {
        grid-template-columns: 1fr;
    }

    .title {
        font-size: 1.25rem;
    }

    .modal-image {
        max-width: 95%;
    }
}
