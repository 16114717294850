.presentation-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .content {
    max-width: 800px;
    margin: auto;
  }
  
  .list {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .contact-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  .contact-table th, .contact-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }
  
  .contact-table th {
    background-color: #f2f2f2;
  }
  
  .image-gallery {
    display: flex;
    gap: 15px;
    margin-top: 20px;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .gallery-image {
    width: 200px;
    height: 150px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 8px;
    transition: transform 0.3s;
  }
  
  .gallery-image:hover {
    transform: scale(1.1);
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-image {
    max-width: 90%;
    max-height: 90%;
    border-radius: 8px;
  }
  
  .close-button {
    position: absolute;
    top: 20px;
    right: 30px;
    background-color: transparent;
    color: white;
    border: none;
    font-size: 30px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .close-button:hover {
    color: #ff0000;
  }
  
  @media (max-width: 768px) {
    .gallery-image {
      width: 100px;
      height: 75px;
    }
  
    .modal-image {
      max-width: 95%;
      max-height: 95%;
    }
  
    .close-button {
      font-size: 25px;
    }
  }
  
  @media (max-width: 480px) {
    .presentation-container {
      padding: 10px;
    }
  
    .title {
      font-size: 20px;
    }
  
    .content {
      max-width: 100%;
    }
  
    .contact-table th, .contact-table td {
      font-size: 12px; 
      padding: 8px;
    }
  
    .list {
      font-size: 12px;
    }
  }
  