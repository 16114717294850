.top-header {
  background-color: black;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  font-size: 14px;
  flex-wrap: wrap;
}

.top-header a {
  color: white;
  text-decoration: none;
}

.top-header a:hover {
  color: #ff6600;
}

.social-icons {
  display: flex;
}

.social-icons svg {
  margin-left: 10px;
  cursor: pointer;
  color: white;
}

.social-icons svg:hover {
  color: #ff6600;
}

nav {
  position: relative;
  left: 0; 
  background-color: rgba(51, 51, 51, 0.7);
  color: white;
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  backdrop-filter: blur(10px);
  z-index: 1000;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: white;
}

.logo-accent {
  color: #ff6600;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0;
  padding: 0;
  transition: all 0.3s ease-in-out;
}

.nav-links.open {
  display: block;
}

.nav-links li {
  position: relative;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 15px;
  padding: 10px;
  transition: color 0.3s, border-bottom 0.3s;
}

.nav-links a:hover {
  color: #ff6600;
  border-bottom: 1px solid #ff6600;
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgba(51, 51, 51, 0.7);
  border-radius: 5px;
  list-style: none;
  padding: 10px 0;
  min-width: 150px;
  z-index: 100;
  backdrop-filter: blur(10px);

}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu li a {
  color: white;
  text-decoration: none;
  padding: 10px;
  display: block;
  font-size: 14px;
  transition: background-color 0.3s;
}

.dropdown-menu li a:hover {
  background-color: #444;
  color: #ff6600;
}

.mobile-menu-icon {
  display: none;
  font-size: 28px;
  cursor: pointer;
  color: white;
}

.navbar li .portail {
  border-radius: 5px;
  border: 1px solid #f4f4f4;
  padding: 5px 10px;
  background-color: transparent;
  color: white;
  transition: all 0.3s ease;
}

.navbar li .portail:hover {
  background-color: #ff6600;
  color: black;
  border-color: #ff6600;
}

@media (max-width: 768px) {
  .logo {
    font-size: 16px;
    font-weight: bold;
    color: white;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: left;
    gap: 10px;
    position: relative;
  }

  .nav-links.open {
    display: flex;
  }

  .mobile-menu-icon {
    display: block;
  }

  .top-header {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5px;
  }

  nav {
    flex-direction: row;
    align-items: center;
  }

  .dropdown-menu {
    position: static;
    width: 100%;
  }
}
