@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  box-sizing: border-box;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

/*footer {
  background-color: #333;
  color: white;
  padding: 10px;
  text-align: center;
}*/
